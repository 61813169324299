/* eslint-disable react-hooks/rules-of-hooks */
import { useFrame, useLoader } from "@react-three/fiber";
import { useEffect, useState } from "react";
import * as THREE from "three";

export default function EnvironmentOuterGlobe({
  reference,
  // position,
  // texture,
  intersectedGlobe,
  lowResImage,
  highResImage,
  depthmapImage,
  userGettingAwayRef
}) {
  const uThreshold = 30.0;
  const highResScale = 1.0;
  const lowResTexture = lowResImage
    ? useLoader(THREE.TextureLoader, lowResImage)
    : null;
  const [highResTexture, depth] =
    highResImage && depthmapImage
      ? useLoader(THREE.TextureLoader, [
          highResImage,
          // "/images/output_frame_8K_depth_map.png",
          depthmapImage,
        ])
      : null;
  const [texturesLoaded, setTexturesLoaded] = useState(false);

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    varying float vDistance;
    varying float vShowRed;
    varying vec4 vDepthMapData;

    uniform int uIntersectedFaceIndex;
    uniform vec3 uFaceA;
    uniform vec3 uFaceB;
    uniform vec3 uFaceC;
    uniform float uThreshold; // Distance threshold

    uniform sampler2D depthMap;
    uniform float displacementScale;
    uniform float minDepth;
    uniform float maxDepth;

    vec3 calculateCentroid(vec3 a, vec3 b, vec3 c) {
      return (a + b + c) / 3.0;
    }

    void main() {
      vUv = uv;
      vPosition = position;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

      if (uIntersectedFaceIndex >= 0) {
        vec3 faceTriangleCentroid = calculateCentroid(uFaceA, uFaceB, uFaceC);
        vDistance = length(vPosition - faceTriangleCentroid);

        // ✅ Working Depth Code
        if (vDistance < uThreshold) {
          vec4 depthData = texture2D(depthMap, uv);
          float r = depthData.r * 255.0;
          float g = depthData.g * 255.0;
          float b = depthData.b * 255.0;

          if (r >= minDepth && r <= maxDepth && g >= minDepth && g <= maxDepth && b >= minDepth && b <= maxDepth) {
            float vDisplacement = depthData.r * displacementScale;
            vec3 transformedPosition = position + normal * vDisplacement;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);
          }
        }

      } else {
        vDistance = 1.0; // Default to a value greater than the threshold
      }
    }
  `;

  const fragmentShader = `
    varying vec2 vUv;
    varying vec3 vPosition;
    varying float vDistance;
    varying float vShowRed;
    varying vec4 vDepthMapData;

    uniform sampler2D uLowResTexture;
    uniform sampler2D uHighResTexture;
    uniform int uIntersectedFaceIndex;
    uniform float uThreshold; // Distance threshold
    uniform float minDepth;
    uniform float maxDepth;
    uniform bool userGettingAway;
    uniform float highResScale; // New uniform for scaling high-res texture

    void main() {
      vec3 color = vec3(0.0);
    
      if (!userGettingAway) {
        color = texture2D(uLowResTexture, vUv).rgb;

        vec2 scaledUv = vUv * highResScale;
        vec4 highResColor = texture2D(uHighResTexture, scaledUv);
        if (highResColor.a > 0.0) {
          color = highResColor.rgb;
          // color = vec3(1.0, 0.0, 0.0);
        }

        // if (uIntersectedFaceIndex >= 0 && vDistance < uThreshold) {
        //   vec2 scaledUv = vUv * highResScale; // Scale the UV coordinates

        //   vec4 highResColor = texture2D(uHighResTexture, scaledUv);

        //   if (highResColor.a > 0.0) {
        //     color = highResColor.rgb;
        //   }
        // }
      }

      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const CustomShader = {
    uniforms: {
      uLowResTexture: { value: null },
      uHighResTexture: { value: null },
      depthMap: { value: null },
      displacementScale: { value: 1.5 },

      uIntersectedFaceIndex: { value: -1 },
      uFaceA: { value: new THREE.Vector3() },
      uFaceB: { value: new THREE.Vector3() },
      uFaceC: { value: new THREE.Vector3() },
      uThreshold: { value: uThreshold },
      minDepth: { value: 50.0 },
      maxDepth: { value: 140.0 },
      userGettingAway: { value: userGettingAwayRef.current },
      highResScale: { value: highResScale }
    },
    vertexShader,
    fragmentShader,
  };

  useFrame(() => {
    if (!reference.current) return;
    if (!intersectedGlobe.current || intersectedGlobe.current.faceIndex < 0)
      return;

    const geometry = reference.current.geometry;
    const index = intersectedGlobe.current.faceIndex;

    const face = geometry.index
      ? [
          geometry.index.array[index * 3],
          geometry.index.array[index * 3 + 1],
          geometry.index.array[index * 3 + 2],
        ]
      : [index * 3, index * 3 + 1, index * 3 + 2];

    const vertices = geometry.attributes.position.array;

    const faceA = new THREE.Vector3(
      vertices[face[0] * 3],
      vertices[face[0] * 3 + 1],
      vertices[face[0] * 3 + 2]
    );

    const faceB = new THREE.Vector3(
      vertices[face[1] * 3],
      vertices[face[1] * 3 + 1],
      vertices[face[1] * 3 + 2]
    );

    const faceC = new THREE.Vector3(
      vertices[face[2] * 3],
      vertices[face[2] * 3 + 1],
      vertices[face[2] * 3 + 2]
    );

    reference.current.material.uniforms.uFaceA.value = faceA;
    reference.current.material.uniforms.uFaceB.value = faceB;
    reference.current.material.uniforms.uFaceC.value = faceC;
    reference.current.material.uniforms.uIntersectedFaceIndex.value =
      intersectedGlobe.current.faceIndex;
  });

  // useEffect(() => {
  //   if (highResTexture && depth) {
  //     debugger
  //     setTexturesLoaded(true);
  //     highResTexture.minFilter = THREE.NearestFilter;
  //     highResTexture.generateMipmaps = false;

  //     depth.minFilter = THREE.NearestFilter;
  //     depth.generateMipmaps = false;
  //   }
  // }, [highResTexture, depth]);

  useEffect(() => {
    if (highResTexture && depthmapImage && depth) {
      setTexturesLoaded(true);
      highResTexture.minFilter = THREE.NearestFilter;
      highResTexture.generateMipmaps = false;

      depth.minFilter = THREE.NearestFilter;
      depth.generateMipmaps = false;
    }
  }, [highResTexture, depthmapImage, depth]);

  if (!texturesLoaded) return null;

  return (
    <mesh ref={reference} rotation={[0, Math.PI / 2, 0]}>
      <sphereGeometry args={[60, 1000, 1000]} />
      <shaderMaterial
        args={[
          {
            uniforms: {
              uLowResTexture: { value: lowResTexture || new THREE.Texture() },
              uHighResTexture: { value: highResTexture || new THREE.Texture() },
              // uHighResTexture: { value: new THREE.Texture() },
              depthMap: { value: depth || new THREE.Texture() },
              displacementScale: { value: 20 },

              uIntersectedFaceIndex: { value: -1 },
              uFaceA: { value: new THREE.Vector3() },
              uFaceB: { value: new THREE.Vector3() },
              uFaceC: { value: new THREE.Vector3() },
              uThreshold: { value: uThreshold },
              minDepth: { value: 50.0 },
              maxDepth: { value: 140.0 },
              userGettingAway: { value: userGettingAwayRef.current },
              highResScale: { value: highResScale }
            },
            vertexShader: CustomShader.vertexShader,
            fragmentShader: CustomShader.fragmentShader,
            side: THREE.BackSide,
          },
        ]}
      />
    </mesh>
  );
}
