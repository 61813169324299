/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { XR, Controllers, Hands, VRButton } from "@react-three/xr";
import { OrbitControls } from "@react-three/drei";
import { Preload } from "@react-three/drei";
import * as THREE from "three";
import DefaultHandControllers from "components/HandTracking/DefaultHandControllers";
import PlayerMovementInfo from "components/PlayerMovementInfo";
import FPSCounter from "components/FPSCounter";
import Loader from "components/Loader";
import PlayerRegionHelper from "components/PlayerRegionHelper";
import useWebSocket, { EventTypes } from "hooks/useWebSocket";
import { Config, DataPointType } from "utils/types";
import { Property, StreamingDataConfigType, StreamingDataType } from "types";
import Scene from "./Scene";
import { DATAPOINTS_DISTANCE_VARIABLE } from "config/constants";

type Props = {
  propertyId: string;
  property: Property;
};

export default function Experience({ propertyId, property }: Props) {
  console.log('🛟🛟🛟🛟 Property State: ', property)
  const [propertyData, setPropertyData] = useState<Property>(property);
  const [currentDatapointIndex, setCurrentDatapointIndex] = useState(0);
  const globeRef = useRef<THREE.Mesh>(null!);

  const config = useMemo(
    () =>
      propertyData.regions.map((region) => ({
        id: region.id,
        label: region.label,
        position: region.position,
        regionCoordinates: region.regionCoordinates,
        regionColor: "#198c5c",
        openingRegions: [],
        dataPoints: region.datapoints.map((datapoint) => ({
          datapointId: datapoint.id,
          name: datapoint.name.toString(),
          color: "lightblue",
          // position: datapoint.position
          //   .map((coord) => coord * DATAPOINTS_DISTANCE_VARIABLE)
          //   .flat(),
          position: [datapoint.position[0] * DATAPOINTS_DISTANCE_VARIABLE, datapoint.position[1], datapoint.position[2] * DATAPOINTS_DISTANCE_VARIABLE],
          url: "",
          regionRef: datapoint.regionRef,
          highResImage: datapoint.highresImageUrl,
          lowResImage: datapoint.lowResImageUrl,
          depthmapImage: datapoint.depthmapImageUrl,
          frontImageUrl: datapoint.frontImageUrl,
          rightImageUrl: datapoint.rightImageUrl,
          leftImageUrl: datapoint.leftImageUrl,
          topImageUrl: datapoint.topImageUrl,
          bottomImageUrl: datapoint.bottomImageUrl,
          backImageUrl: datapoint.backImageUrl,
        })) as DataPointType[],
      })) as Config[],
    [propertyData]
  );

  const dataPointList = useMemo(() => {
    return config.map((elem) => elem.dataPoints).flat();
  }, [config]);

  const openingList = useMemo(() => {
    return config
      ?.map((region) => region.openingRegions)
      .filter((opening) => !!opening)
      .flat();
  }, [config]);

  const { socket, emitInitiateDataStreamingProcessEvent } = useWebSocket();

  useEffect(() => {
    emitInitiateDataStreamingProcessEvent(propertyId);

    return () => {
      globeRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(
        EventTypes.SYNC_STREAMING_DATA,
        (data: StreamingDataConfigType) => {
          console.log("🔳🔳🔳🔳🔳🔳🔳 Sync Streaming Data: ", data);

          let regions = [];
          for (let index = 0; index < property.regions.length; index++) {
            const region = property.regions[index];

            let temp = { ...region };

            for (let i = 0; i < region.datapoints.length; i++) {
              const datapoint = region.datapoints[i];

              if (datapoint.id === data.datapointId) {
                if (data.type === StreamingDataType.HIGH_RESOLUTION) {
                  temp.datapoints[i] = {
                    ...datapoint,
                    highresImageUrl: data.imageBase64,
                  };
                } else if(data.type === StreamingDataType.LOW_RESOLUTION) {
                  temp.datapoints[i] = {
                    ...datapoint,
                    lowResImageUrl: data.imageBase64,
                  };
                } else {
                  temp.datapoints[i] = {
                    ...datapoint,
                    depthmapImageUrl: data.imageBase64,
                  };
                }
              }
            }

            regions.push(temp);
          }

          // console.log("✅✅✅✅ Updated regions: ", regions);
          setPropertyData({
            ...property,
            regions,
          });
        }
      );
    } else {
      console.log("Socket is not initialized.");
    }

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT)
        socket.disconnect()
      }
    }
  }, [socket]);

  useEffect(() => {
    console.log("✅✅✅✅  Property Data: ", propertyData);
  }, [propertyData]);

  return (
    <>
      <VRButton />

      {/* <input
        type="number"
        value={currentDatapointIndex}
        onChange={(e) => setCurrentDatapointIndex(parseInt(e.target.value))}
      /> */}

      <Canvas
        frameloop="demand"
        dpr={[1, 1.5]}
        shadows
        camera={{
          fov: 100,
          position: [0, 0, 0.1],
        }}
        style={{
          backgroundColor: "#000000",
        }}
      >
        <XR referenceSpace="local-floor">
          <Suspense fallback={<Loader />}>
            <ambientLight intensity={10} />

            <spotLight
              position={[0, 20, 10]}
              angle={0.3}
              penumbra={0.5}
              intensity={2}
              castShadow
            />

            {config && (
              <Scene
                currentDatapointIndex={currentDatapointIndex}
                setCurrentDatapointIndex={setCurrentDatapointIndex}
                propertyConfig={config}
                dataPointList={dataPointList}
                openingList={openingList}
                globeRef={globeRef}
              />
            )}

            <Controllers />
            <Hands />
            <DefaultHandControllers />
            <PlayerMovementInfo />

            {process.env.REACT_APP_DEBUG === "true" && (
              <>
                <PlayerRegionHelper />
              </>
            )}

            <OrbitControls />
            <Preload all />
            <FPSCounter />
          </Suspense>
        </XR>
      </Canvas>
    </>
  );
}
