import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Box3, BoxHelper } from "three";
import DataPoint from "./DataPoint";
import { Config, DataPointType } from "utils/types";
import useMovementHelper from "hooks/useMovementHelper";
import EnvironmentOuterGlobe from "./EnvironmentOuterGlobe";
import RoomFloor from "./Floor/RoomFloor";
import Opening from "./Floor/Opening";
import { useXR } from "@react-three/xr";
import ArrowHelper from "./ui/ArrowHelper";
import usePlayerWithDev from "hooks/usePlayerWithDev";

type Props = {
  propertyConfig: Config[];
  dataPointList: DataPointType[];
  openingList: any[];
  currentDatapointIndex: any;
  setCurrentDatapointIndex: any;
  globeRef: any;
};

function Scene({
  currentDatapointIndex,
  setCurrentDatapointIndex,
  propertyConfig,
  dataPointList,
  openingList,
  globeRef,
}: Props) {
  console.log('❌❌❌❌❌❌ dataPointList: ', dataPointList)

  const { checkIfPlayerIsCloseEnoughToDataPointe, checkIsDataPointViewable } =
    useMovementHelper();
  const { session, referenceSpace, isPresenting } = useXR();
  const { scene } = useThree();
  const { player } = usePlayerWithDev();
  const devPlayerRef = useRef<THREE.Mesh>();

  const [filteredDataPoints, setFilteredDataPoints] = useState<DataPointType[]>(
    []
  );

  const roomRefs = useRef<THREE.Mesh[]>([]);
  const openingRefs = useRef<THREE.Mesh[]>([]);
  const roomRaycasterRef = useRef(new THREE.Raycaster());
  const openingRaycasterRef = useRef(new THREE.Raycaster());
  const roomIntersectionArrowRef = useRef<THREE.ArrowHelper>(null!);

  const boxRef = useRef();
  const boxHelperRef = useRef<THREE.BoxHelper>();

  const intersectedRoom =
    useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>>();
  const intersectedOpenings =
    useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>[]>();
  const intersectedGlobe =
    useRef<THREE.Intersection<THREE.Object3D<THREE.Object3DEventMap>>>();

  useFrame(() => {
    if (!roomRefs.current.every((object) => object !== null)) return;

    const rayOrigin = player.position;
    const rayDirection = new THREE.Vector3(0, -1, 0).normalize();
    const raycaster = roomRaycasterRef.current;
    raycaster.set(rayOrigin, rayDirection);

    const intersectedObjects = raycaster.intersectObjects(roomRefs.current);

    intersectedRoom.current = intersectedObjects[0];
  });

  useFrame(() => {
    if (!globeRef.current) return;
    if (!isPresenting) return;
    if (!session) return;

    session
      .requestReferenceSpace(referenceSpace)
      .then((refSpace: XRReferenceSpace) => {
        session.requestAnimationFrame((timestamp, xrFrame) => {
          const pose = xrFrame.getViewerPose(refSpace);

          const orientation = pose.transform.orientation;

          const quaternion = new THREE.Quaternion(
            orientation.x,
            orientation.y,
            orientation.z,
            orientation.w
          );

          const direction = new THREE.Vector3(0, 0, -1)
            .applyQuaternion(quaternion)
            .normalize();

          const rayOrigin = player.position;
          const rayDirection = direction;
          const raycaster = openingRaycasterRef.current;
          raycaster.set(rayOrigin, rayDirection);

          roomIntersectionArrowRef.current.setDirection(direction);
          const { x, y, z } = player.position;
          roomIntersectionArrowRef.current.position.set(x, y, z);

          if (!openingRefs.current.every((object) => object !== null)) return;

          if (!globeRef.current) return;

          const intersectedObject = raycaster.intersectObjects([
            globeRef.current,
          ]);

          if (intersectedObject.length === 0) return;

          intersectedGlobe.current = intersectedObject[0];
        });
      });
  });

  useFrame(() => {
    if (!isPresenting) {
      if (!roomIntersectionArrowRef.current) return;
      if (!devPlayerRef.current) return;

      const rayOrigin = player.position;
      const rayDirection = devPlayerRef.current.getWorldDirection(
        new THREE.Vector3()
      );
      const raycaster = openingRaycasterRef.current;
      raycaster.set(rayOrigin, rayDirection);

      const intersectedObjects = raycaster.intersectObjects(
        openingRefs.current
      );
      intersectedOpenings.current = intersectedObjects;

      roomIntersectionArrowRef.current.setDirection(
        devPlayerRef.current.getWorldDirection(new THREE.Vector3()).clone()
      );
    } else {
      session
        .requestReferenceSpace(referenceSpace)
        .then((refSpace: XRReferenceSpace) => {
          session.requestAnimationFrame((timestamp, xrFrame) => {
            const pose = xrFrame.getViewerPose(refSpace);

            const orientation = pose.transform.orientation;

            const quaternion = new THREE.Quaternion(
              orientation.x,
              orientation.y,
              orientation.z,
              orientation.w
            );

            const direction = new THREE.Vector3(0, 0, -1)
              .applyQuaternion(quaternion)
              .normalize();

            const rayOrigin = player.position;
            const rayDirection = direction;
            const raycaster = openingRaycasterRef.current;
            raycaster.set(rayOrigin, rayDirection);

            roomIntersectionArrowRef.current.setDirection(direction);
            const { x, y, z } = player.position;
            roomIntersectionArrowRef.current.position.set(x, y, z);

            if (!openingRefs.current.every((object) => object !== null)) return;

            const intersectedObjects = raycaster.intersectObjects(
              openingRefs.current
            );
            intersectedOpenings.current = intersectedObjects;
          });
        });
    }
  });

  /**
   * Updating Globe position with player position
   * This will remove the stretching or warping issue
   */
  useFrame(() => {
    if (!globeRef.current) return;
    globeRef.current.position.copy(
      new THREE.Vector3(
        player.position.x,
        // GLOBE_HEIGHT_VARIABLE,
        player.position.y,
        player.position.z
      )
    );
  });

  /**
   * Box Helper
   */
  // useEffect(() => {
  //   const boxHelper = new BoxHelper(boxRef.current, 0xff0000);
  //   scene.add(boxHelper);

  //   return () => {
  //     scene.remove(boxHelper);
  //   };
  // }, [scene]);

  useEffect(() => {
    boxHelperRef.current = new BoxHelper(boxRef.current, 0xff0000);
    scene.add(boxHelperRef.current);

    return () => {
      scene.remove(boxHelperRef.current);
    };
  }, [scene]);

  const userGettingAwayRef = useRef(false);

  // useFrame(() => {
  //   if (!boxRef.current) return;

  //   // Calculate the bounded box distance from the player
  //   const boundingBox = new Box3().setFromObject(boxRef.current);
  //   const distance = boundingBox.distanceToPoint(player.position);
  //   console.log('🌺🌺🌺🌺 Distance from the bounding box:', distance);

  //   if (distance > 5) {
  //     userGettingAwayRef.current = true;
  //     setFilteredDataPoints(dataPointList)
  //   } else {
  //     userGettingAwayRef.current = false;
  //   }
  // });

  useFrame((state, delta) => {
    if (!intersectedOpenings.current) return;
    if (!isPresenting) return;
    if (!boxRef.current) return;

    let pointsWithinCircularRegion: DataPointType[] = [];

    for (let index = 0; index < dataPointList.length; index++) {
      const element = dataPointList[index];

      if (index !== currentDatapointIndex) {
        const { distance, isViewable } = checkIsDataPointViewable(
          element.position
        );

        if (isViewable) {
          pointsWithinCircularRegion.push({
            ...element,
            name: element.url.replace("/images/office/", "").toUpperCase(),
            distance,
          });
        }

        if (checkIfPlayerIsCloseEnoughToDataPointe(distance)) {
          setCurrentDatapointIndex(index);
        }
      }
    }

    // Calculate the bounded box distance from the player
    // const boundingBox = new Box3().setFromObject(boxRef.current);
    // const distance = boundingBox.distanceToPoint(player.position);
    // console.log('🌺🌺🌺🌺 Distance from the bounding box:', distance);

    if (!intersectedRoom.current) {
      userGettingAwayRef.current = true;
      setFilteredDataPoints(dataPointList)

      boxHelperRef.current.visible = true;
    } else {
      userGettingAwayRef.current = false;
      boxHelperRef.current.visible = false;

      const currentRoomRegionName = intersectedRoom.current.object.name;

      if (
        pointsWithinCircularRegion.some(
          (elem) => elem.regionRef !== currentRoomRegionName
        )
      ) {
        if (intersectedOpenings.current.length > 0) {
          setFilteredDataPoints(pointsWithinCircularRegion);
        } else {
          setFilteredDataPoints(
            pointsWithinCircularRegion.filter(
              (elem) => elem.regionRef === currentRoomRegionName
            )
          );
        }
      } else {
        setFilteredDataPoints(pointsWithinCircularRegion);
      }
    }

    if (boxHelperRef.current) {
      boxHelperRef.current.update();
    }
  });


  return (
    <>
      {/* {filteredDataPoints?.length > 0 &&
        filteredDataPoints?.map((item, index) => (
          <DataPoint
            key={index}
            index={index}
            state={item}
            currentDatapointIndex={currentDatapointIndex}
          />
        ))} */}

      <group ref={boxRef}>
        {filteredDataPoints?.length > 0 &&
          filteredDataPoints?.map((item, index) => (
            <DataPoint
              key={index}
              index={index}
              state={item}
              currentDatapointIndex={currentDatapointIndex}
              userGettingAwayRef={userGettingAwayRef}
            />
          ))}
      </group>

      {/* 
      <group ref={boxRef}>
        {dataPointList?.length > 0 &&
          dataPointList?.map((item, index) => (
            <DataPoint
              key={index}
              index={index}
              state={item}
              currentDatapointIndex={currentDatapointIndex}
              userGettingAwayRef={userGettingAwayRef}
            />
          ))}
      </group> */}

      {propertyConfig.map((room, index) => (
        <RoomFloor
          key={room.id}
          name={room.id}
          position={room.position}
          roomRef={(el) => (roomRefs.current[index] = el)}
          points={room.regionCoordinates}
          color={room.regionColor}
        />
      ))}

      {openingList.length > 0 &&
        openingList.map((opening, index) => (
          <Opening
            name={opening.id}
            openingRef={(el) => (openingRefs.current[index] = el)}
            coordinates={opening.coordinates}
            position={opening.positions}
          />
        ))}

      {/* <ArrowHelper
        arrowRef={roomIntersectionArrowRef}
        direction={new THREE.Vector3(1, 0, 0)}
        origin={new THREE.Vector3(10, 0, 0)}
        length={20}
        color="blue"
      /> */}

      {!isPresenting && (
        <ArrowHelper
          arrowRef={roomIntersectionArrowRef}
          direction={new THREE.Vector3(1, 0, 0)}
          origin={player.position}
          length={20}
          color="#ff5e51"
        />
      )}

      <EnvironmentOuterGlobe
        reference={globeRef}
        intersectedGlobe={intersectedGlobe}
        lowResImage={dataPointList[currentDatapointIndex].lowResImage}
        highResImage={dataPointList[currentDatapointIndex].frontImageUrl}
        depthmapImage={dataPointList[currentDatapointIndex].depthmapImage}
        userGettingAwayRef={userGettingAwayRef}
      />

      {!isPresenting && (
        <mesh
          ref={devPlayerRef}
          position={player.position}
          rotation={[0, Math.PI * -1, 0]}
        >
          <boxGeometry args={[2, 2, 2]} />
          <meshBasicMaterial color="purple" transparent />
        </mesh>
      )}
    </>
  );
}

export default Scene;
